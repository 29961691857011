<template>
  <div>
    <h3>Email</h3>
    <v-btn
      text
      :to="{ name: 'manage-websites', params: { domainname: siteUrl } }"
      outlined
      class="btn btn-lg btn-secondary-main"
      style="background: transparent"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <span
      class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right"
    >
      <i class="fa fa-globe"></i>
      {{ siteUrl }}
    </span>

    <v-app>
      <div class="row mt-2">
        <div class="col-xl-12">
          <KTPortlet v-bind:title="''">
            <template v-slot:body>
              <v-row
                v-if="
                  (website.subscription &&
                    website.subscription.type == 'paid') ||
                  website.lifetime_membership
                "
              >
                <v-col cols="8" class="p-15">
                  <h3>Secure email hosting for your business</h3>
                  <p>
                    Host your business email on a secure, encrypted,
                    privacy-guaranteed, and ad-free email service.
                  </p>
                  <h3>Stay professional with email@yourdomain.com</h3>
                  <p>
                    Create a domain for your business and set up custom email
                    addresses for users. Unique and professional email addresses
                    give your company the visibility and authenticity it
                    deserves.
                  </p>
                  <h3>Extensive Control Panel</h3>
                  <p>
                    Zoho Mail's powerful Control Panel is the admin's go-to
                    place for all settings, configuration, and customization.
                    Add users, manage group aliases, set up policies to moderate
                    business email content, and more.
                  </p>
                  <h3>Email retention and e-Discovery</h3>
                  <p>
                    Retain emails across your organization for a specified
                    period to comply with company standards and to counter legal
                    attacks. e-Discovery helps discover such retained emails
                    quickly.
                  </p>
                  <h3>Mail on Mobile</h3>
                  <p>
                    Manage your business communications even when you're away
                    from your desk. Native mobile apps for Mail, Mail Admin, and
                    Streams make staying in touch on the go easy and effortless.
                  </p>
                  <h2>Private. Secure. No ads.</h2>

                  <v-row
                    v-if="
                      !website.zoho_mail ||
                      website.zoho_mail.status == 'verification_pending'
                    "
                  >
                    <v-col cols="12">
                      <p>Integrated with Yelko Platform for easy setup</p>
                      <p>
                        Enter your business name or personal name to create a
                        new zoho account. If you already have a zoho account,
                        with your login email address, we won't be able to
                        create account for that. Please make sure you do not
                        have any old Zoho Email account associated with your
                        email address before continue.
                      </p>
                      <h5>
                        <a
                          href="https://www.zoho.com/mail/"
                          target="_blank"
                        >Click here to read more about Zoho Email</a>
                      </h5>

                      <h6>We need your organisation name to connect your mail.</h6>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="website.domain_name"
                        label="Domain Name"
                        disabled
                        required
                        outlined
                        densed
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="zoho_mail.org_name"
                        label="Your personal/business name "
                        required
                        outlined
                        densed
                      ></v-text-field>
                      <p
                        class="text-danger font-weight-bold"
                        v-if="$v.zoho_mail.org_name.$error"
                      >Personal name or business name is required</p>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="zoho_mail.email"
                        label="Your Email "
                        required
                        outlined
                        densed
                      ></v-text-field>

                      <p
                        class="text-danger font-weight-bold"
                        v-if="$v.zoho_mail.email.$error && !verificationBox"
                      >Email is required and must be valid</p>

                      <p
                        class="text-danger font-weight-bold"
                        v-if="!$v.zoho_mail.email.isUnique && !verificationBox"
                      >Email already taken.</p>
                    </v-col>

                    <v-col cols="6" v-if="verificationBox">
                      <v-text-field
                        v-model="zoho_mail.email_verification_code"
                        label="Email verification code "
                        required
                        outlined
                        densed
                      ></v-text-field>
                      <p
                        class="text-danger font-weight-bold"
                        v-if="$v.zoho_mail.email_verification_code.$error"
                      >This field cannot be empty</p>
                    </v-col>

                    <v-col v-if="!verificationBox" cols="6"></v-col>

                    <v-col cols="3"></v-col>
                    <v-col cols="4">
                      <v-btn
                        @click="saveZohoSetting"
                        v-if="!zoho_mail.is_email_verified && verificationBox"
                        color="primary"
                      >Verify email & Proceed to Zoho Emails</v-btn>
                    </v-col>

                    <v-col cols="3">
                      <v-btn
                        @click="resendEmailVerification"
                        v-if="!zoho_mail.is_email_verified && verificationBox"
                        color="primary"
                        :disabled="resendVerificationWait"
                      >Resend email</v-btn>
                    </v-col>

                    <v-btn
                      v-if="!verificationBox"
                      @click="requestEmailVerification"
                      color="primary"
                    >Continue</v-btn>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-stepper
                        alt-labels
                        v-if="
                          website.zoho_mail &&
                          website.zoho_mail.status == 'started'
                        "
                      >
                        <v-stepper-header>
                          <v-stepper-step step="1" complete>Connecting Domain</v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step
                            step="2"
                            :complete="
                              (website.zoho_mail &&
                                website.zoho_mail.step == 2) ||
                              website.zoho_mail.step == 3 ||
                              website.zoho_mail.step == 4 ||
                              website.zoho_mail.step == 5 ||
                              website.zoho_mail.step == 6
                            "
                          >Adding Oranisation</v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step
                            step="3"
                            :complete="
                              (website.zoho_mail &&
                                website.zoho_mail.step == 3) ||
                              website.zoho_mail.step == 4 ||
                              website.zoho_mail.step == 5 ||
                              website.zoho_mail.step == 6
                            "
                          >Verifying Domain</v-stepper-step>

                          <v-divider></v-divider>
                          <v-stepper-step
                            step="4"
                            :complete="
                              (website.zoho_mail &&
                                website.zoho_mail.step == 4) ||
                              website.zoho_mail.step == 5 ||
                              website.zoho_mail.step == 6
                            "
                          >Enabling Email</v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step
                            step="5"
                            :complete="
                              website.zoho_mail.step == 5 ||
                              website.zoho_mail.step == 6
                            "
                          >DKIM & MX Record</v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step
                            step="6"
                            :complete="
                              website.zoho_mail && website.zoho_mail.step == 6
                            "
                          >Complete</v-stepper-step>
                        </v-stepper-header>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="4" class="text-center">
                  <img src="/assets/media/icons/zoho-mail.png" width="200px" />

                  <v-btn
                    v-if="
                      website.zoho_mail &&
                      website.zoho_mail.status == 'complete'
                    "
                    color="white--text v-btn theme--light elevation-2 v-size--large primary"
                    class="btn-save-popup btn-sm pull-right"
                    text
                    @click="showDialog"
                  >Add User</v-btn>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" align="center">
                  <img
                    width="200"
                    all
                    position="center"
                    class="img-fluid"
                    contain
                    src="/assets/media/illustrations/undraw_Notify_re_65on.svg"
                  />
                </v-col>

                <v-col cols="12" align="center">
                  <h2>Plan not eligible</h2>
                  <p>
                    Free domain are not entitled to email service and is only
                    available to custom domains.
                  </p>
                  <p>
                    Upgrade your plan and connect your domain and to get domain
                    email address..
                    <a
                      class="kt-link"
                      href="#"
                      @click.prevent="manageSubscription"
                    >Upgrade Plan</a>
                  </p>
                </v-col>
              </v-row>
            </template>
          </KTPortlet>
        </div>
      </div>

      <v-dialog scrollable v-model="userModal" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Add User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Enter Primary Email"
                      v-model="zoho_mail_user.primary_email"
                      :placeholder="'name@' + website.domain_name"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <p
                      class="text-danger font-weight-bold"
                      v-if="$v.zoho_mail_user.primary_email.$error"
                    >Please enter a primary email</p>

                    <v-text-field
                      label="Enter Display Name"
                      v-model="zoho_mail_user.display_name"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <p
                      class="text-danger font-weight-bold"
                      v-if="$v.zoho_mail_user.display_name.$error"
                    >Please enter a name of user</p>

                    <v-text-field
                      label="Enter Password"
                      v-model="zoho_mail_user.password"
                      :type="'password'"
                      required
                      dense
                      outlined
                    ></v-text-field>

                    <p
                      class="text-danger font-weight-bold"
                      v-if="$v.zoho_mail_user.password.$error"
                    >Please enter a password</p>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
            <v-btn
              color="white--text v-btn theme--light elevation-2 v-size--large primary"
              class="btn-save-popup"
              text
              :loading="loading"
              @click.once="addMailUser"
            >Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import {
  email,
  required,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";
import Website from "@/services/Websites/WebsiteService";
const WebsiteService = new Website();
export default {
  name: "website-mail",
  components: {
    KTPortlet
  },

  validations() {
    if (this.verificationBox) {
      return {
        zoho_mail: {
          email_verification_code: { required },
          org_name: { required },
          email: {
            required,
            email,
            async isUnique(value) {
              if (value === "") return true;
              if (value.length > 7) {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    WebsiteService.checkUniqueEmail(this.zoho_mail).then(
                      response => {
                        resolve(response.data);
                      }
                    );
                  }, 500);
                });
              }
            }
          }
        },

        zoho_mail_user: {
          primary_email: { required },
          display_name: { required },
          password: { required }
        }
      };
    } else {
      return {
        zoho_mail: {
          org_name: { required },
          email: {
            required,
            email,
            async isUnique(value) {
              if (value === "") return true;
              if (value.length > 7) {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    WebsiteService.checkUniqueEmail(this.zoho_mail).then(
                      response => {
                        resolve(response.data);
                      }
                    );
                  }, 500);
                });
              }
            }
          }
        },

        zoho_mail_user: {
          primary_email: { required },
          display_name: { required },
          password: { required }
        }
      };
    }
  },

  data() {
    return {
      userModal: false,
      loading: false,
      verificationBox: false,
      resendVerificationWait: false,
      website: {
        domain_name: null,
        sub_domain_name: null,
        user_id: null,
        template_id: null,
        has_dns: 0,
        has_ssl: 0,
        ssl_country: null,
        ssl_state: null,
        ssl_city: null,
        ssl_organisation: null,
        ssl_organisation_unit: null,
        is_custom_domain: null,
        plan_id: null,
        plan_expiry_date: null,
        lifetime_membership: null,
        is_linked_organisation: null,
        is_active: 0
      },
      zoho_mail: {
        org_name: null,
        email: "",
        email_verification_code: null,
        is_email_verified: 0
      },
      zoho_mail_user: {
        primary_email: "",
        display_name: "",
        password: ""
      }
    };
  },
  methods: {
    showDialog() {
      this.userModal = true;
    },
    closeDialog() {
      this.userModal = false;
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    imageChange(e) {
      this.url = URL.createObjectURL(e);
    },

    getWebsiteDetail() {
      WebsiteService.show(this.siteUrl).then(res => {
        this.website = res.data.website;
        if (res.data.website.zoho_mail) {
          this.zoho_mail = res.data.website.zoho_mail;
        }
      });
    },

    requestEmailVerification() {
      this.$v.$touch();
      if (!this.$v.zoho_mail.$error) {
        this.loading = true;
        WebsiteService.requestZohoMailVerification(
          this.siteUrl,
          this.zoho_mail
        ).then(res => {
          this.loading = false;
          this.getWebsiteDetail();
          this.verificationBox = true;
          this.$snotify.success(
            "Email sent. Please check email for verification code"
          );
        });
      }
    },
    resendEmailVerification() {
      this.loading = true;
      WebsiteService.requestZohoMailVerification(this.siteUrl, this.zoho_mail)
        .then(res => {
          this.loading = false;
          this.getWebsiteDetail();
          this.verificationBox = true;
          this.resendVerificationWait = true;
          if (res.data.status == "DELAY") {
            this.$snotify.success("Email already sent please wait for a while");
          } else {
            this.$snotify.success(
              "Email sent. Please check email for verification code"
            );
          }

          setTimeout(function() {
            this.resendVerificationWait = false;
          }, 60000);
        })
        .catch(error => {
          this.$snotify.error("Email already sent please wait for a while");
        });
    },
    saveZohoSetting() {
      this.$v.$touch();
      if (!this.$v.zoho_mail.email_verification_code.$error) {
        this.loading = true;
        WebsiteService.activateZohoMail(this.siteUrl, this.zoho_mail)
          .then(res => {
            this.loading = false;
            this.$v.$reset();
            this.getWebsiteDetail();
            this.$snotify.success("Mail connection in process");
          })
          .catch(error => {
            this.$snotify.error("Verification code mismtached");
          });
      }
    },

    addMailUser() {
      this.$v.$touch();
      this.loading = true;
      if (!this.$v.zoho_mail_user.$error) {
        WebsiteService.addZohoMailUser(this.siteUrl, this.zoho_mail_user)
          .then(res => {
            this.userModal = false;
            this.loading = false;
            this.zoho_mail_user = {
              primary_email: "",
              display_name: "",
              password: ""
            };
            this.getWebsiteDetail();
            this.$snotify.success("User added successfully");
          })
          .catch(({ response }) => {
            this.$snotify.error("Problem adding user");
          });
      }
    },
    manageSubscription() {
      window.location.href =
        process.env.VUE_APP_SERVER_URL + this.siteUrl + "/pricing";
    }
  },
  computed: {
    rows() {
      return this.items.length;
    },
    siteUrl() {
      return this.$route.params.domainname;
    }
  },
  mounted() {
    // console.log(this.$v);
    this.getWebsiteDetail();
    this.verificationBox =
      this.zoho_mail.email_verification_code != null &&
      this.zoho_mail.is_email_verified == 0
        ? true
        : false;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Manage SSL", route: "/websites" }
    ]);
  }
};
</script>
